import React from "react";
import { DeviceMenuSearchResultTemplate } from "themes/administration/device-menu/components";

class DeviceMenuSearchResult extends React.Component {
    render() {
        return <DeviceMenuSearchResultTemplate {...this.props} />;
    }
}

export default DeviceMenuSearchResult;
