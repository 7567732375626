import React from "react";
import { DeviceDowntimeEditTemplate } from "themes/application/device-settings/components";

class DeviceDowntimeEdit extends React.Component {
    render() {
        return <DeviceDowntimeEditTemplate {...this.props} />;
    }
}

export default DeviceDowntimeEdit;
