import React from "react";
import { PropTypes } from "prop-types";
import { deviceMenuTypes } from "common/constants";

function DeviceMenuTypeIconTemplate({ type }) {
    if (!type) {
        return null;
    }

    let icon = null;
    switch (type.abrv) {
        case deviceMenuTypes.search:
            icon = "search";
            break;
        case deviceMenuTypes.station:
            icon = "stations";
            break;
        case deviceMenuTypes.podcast:
        case deviceMenuTypes.directory:
        case deviceMenuTypes.torahAnytime:
        case deviceMenuTypes.nakiTalesCollection:
        case deviceMenuTypes.nakiTalesTitle:
            icon = "podcasts";
            break;
        case deviceMenuTypes.shuffle:
            icon = "shuffle";
            break;
        case deviceMenuTypes.nakiNigunArtist:
        case deviceMenuTypes.nakiNigunAlbum:
            icon = "nigun";
            break;
        default:
            break;
    }

    if (!icon) {
        return null;
    }

    return <i className={`u-icon u-icon--med u-icon--${icon} u-mar--right--tny`}></i>;
}

DeviceMenuTypeIconTemplate.propTypes = {
    type: PropTypes.object,
};

export default DeviceMenuTypeIconTemplate;
