import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldCheckbox } from "mrb/components/checkbox";
import { MrbFieldSelect } from "mrb/components/select";
import { map } from "lodash";
import { defaultTemplate } from "common/hoc";
import { MrbCheckbox } from "mrb/components/checkbox";

function DeviceDowntimeEditTemplate({ store, t }) {
    const { form, dayHours, timezoneSelectStore, isAllDayChecked, disableEntireDay } = store;
    return (
        <React.Fragment>
            <div className="u-animation--fadeIn">
                <h1 className="u-type--base u-mar--bottom--sml">Device Downtime</h1>
            </div>
            <div className="u-animation--fadeIn">
                <div className="u-mar--bottom--sml">
                    <MrbFieldSelect
                        store={timezoneSelectStore}
                        classNameExtend="c-dropdown--dark"
                        fieldClassNameExtend="c-form__field--med"
                        t={t}
                    />
                </div>
            </div>
            <div className="u-animation--fadeIn">
                <div className="c-device__downtime">
                    <div className="c-device__downtime__days">
                        {renderDowntimeDays(form.$("prohibitedTime"), disableEntireDay, isAllDayChecked)}
                    </div>

                    <div className="c-device__downtime__hours">
                        {renderDowntimeGrid(form.$("prohibitedTime"), dayHours)}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

function renderDowntimeGrid(field, dayHours) {
    return (
        <React.Fragment>
            {renderHeader(dayHours)}
            {field.map((dayField) => {
                return (
                    <div key={dayField.key} className="c-device__downtime__list">
                        {dayField.map((timeField) => {
                            return (
                                <div key={timeField.key} className="c-device__downtime__item">
                                    <MrbFieldCheckbox field={timeField} classNameExtend="c-input--check--secondary" />
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </React.Fragment>
    );
}

function renderDowntimeDays(field, disableEntireDay, isAllDayChecked) {
    return (
        <React.Fragment>
            {field.map((dayField) => {
                return (
                    <div key={dayField.key}>
                        <MrbCheckbox
                            id={dayField.key}
                            label={dayField.label}
                            value={isAllDayChecked(dayField.value)}
                            onChange={(event) => disableEntireDay(dayField.key, event.target.checked)}
                        />{" "}
                    </div>
                );
            })}
        </React.Fragment>
    );
}

function renderHeader(dayHours) {
    return (
        <div className="c-device__downtime__list">
            {map(dayHours, (dayHour) => {
                return (
                    <div key={dayHour.abrv} className="c-device__downtime__item">
                        {dayHour.name}
                    </div>
                );
            })}
        </div>
    );
}

DeviceDowntimeEditTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(DeviceDowntimeEditTemplate);
