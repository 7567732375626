import React from "react";
import ContentLoader from "react-content-loader";

const UserEditLoaderTemplate = ({ props, button, checkBox, userProfile }) => {
    const screenwidth = window.innerWidth;
    const viewHeight = screenwidth > 1198 ? 185 : 300;
    let viewWidth;

    if (screenwidth > 1439) {
        viewWidth = screenwidth - 650;
    } else if (screenwidth > 1200) {
        viewWidth = Math.floor((screenwidth - 200) / 2);
    } else if (screenwidth > 990) {
        viewWidth = screenwidth - 200;
    } else {
        viewWidth = screenwidth - 30;
    }

    const items = [];
    let y1 = 0;
    let y2 = 25;

    for (let i = 0; i < 3; i++) {
        items.push(
            <React.Fragment key={i}>
                <rect x="0" y={y1} rx="6" ry="6" width="90" height="10" />
                <rect x="0" y={y2} rx="6" ry="6" width="100%" height="43" />
            </React.Fragment>
        );

        y1 += 90;
        y2 += 90;
    }

    return (
        <React.Fragment>
            <ContentLoader
                speed={2}
                width={viewWidth}
                height={"100%"}
                viewBox={`0 0 ${viewWidth} ${viewHeight}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                {screenwidth > 1198 ? (
                    <UserEditLoaderLrg checkBox={checkBox} />
                ) : (
                    <React.Fragment>
                        {items}
                        {/* prettier-ignore */}
                        <rect x="25" y={y1 + 3} rx="3" ry="3" width="100" height="10" />
                        {/* prettier-ignore */}
                        <rect x="0" y={y1} rx="3" ry="3" width="15" height="15" />
                    </React.Fragment>
                )}
            </ContentLoader>
            {button && <Button />}
        </React.Fragment>
    );
};

const Button = () => {
    return (
        <ContentLoader
            speed={2}
            width="100"
            height="30"
            viewBox={`0 0 ${100} 30`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
        >
            <rect x="0" y="0" rx="3" ry="3" width="100" height="30" />
        </ContentLoader>
    );
};

const UserEditLoaderLrg = ({ checkBox }) => {
    return (
        <React.Fragment>
            <rect x="0" y="5" rx="3" ry="3" width="10%" height="10" />
            <rect x="0" y="30" rx="3" ry="3" width="49%" height="43" />

            <rect x="51%" y="5" rx="3" ry="3" width="10%" height="10" />
            <rect x="51%" y="30" rx="3" ry="3" width="49%" height="43" />

            <rect x="0" y="115" rx="3" ry="3" width="10%" height="10" />
            <rect x="0" y="135" rx="3" ry="3" width="49%" height="43" />

            {checkBox && (
                <React.Fragment>
                    {/* prettier-ignore */}
                    <rect x="54%" y="137" rx="3" ry="3" width="100" height="10" />
                    {/* prettier-ignore */}
                    <rect x="51%" y="135" rx="3" ry="3" width="15" height="15" />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default UserEditLoaderTemplate;
