import { MrbBaseRouteStore } from "mrb/core";
import { ProductService, UserAccountService, DeviceService, PaymentService } from "common/services";

class UserEditRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = this.rootStore.application.baasic.membershipModule.user;
        this.productService = rootStore.createApplicationService(ProductService);
        this.userAccountService = rootStore.createApplicationService(UserAccountService);
        this.deviceService = rootStore.createApplicationService(DeviceService);
        this.paymentService = rootStore.createApplicationService(PaymentService);
    }

    async getResource(id) {
        const response = await this.userAccountService.get(id);
        return response.data;
    }

    update(resource) {
        return this.userAccountService.update(resource);
    }

    lock(resource) {
        return this.service.lock(resource);
    }

    unlock(resource) {
        return this.service.unlock(resource);
    }

    disapprove(resource) {
        return this.service.disapprove(resource);
    }

    approve(resource) {
        return this.service.approve(resource);
    }

    changeUserPassword(userId, resource) {
        return this.service.changePassword(userId, resource);
    }

    async getRoles() {
        const response = await this.rootStore.application.baasic.membershipModule.role.find();
        return response.data.item;
    }

    async getAllSubscriptionProducts() {
        const response = await this.productService.getAllSubscriptions();
        return response.data;
    }

    async activateSubscription(resource) {
        const response = await this.userAccountService.activateSystemTypeSubscription(resource);
        return response.data;
    }

    async cancelInternalSubscription(subscriptionId) {
        const response = await this.userAccountService.cancelSubscription(subscriptionId);
        return response.data;
    }

    async cancelBundleSubscription(subscriptionId) {
        const response = await this.paymentService.cancelSubscription(subscriptionId);
        return response.data;
    }

    async getUserSubscriptionInfo(userId) {
        const response = await this.userAccountService.getUserSubscriptionInfo(userId, {
            embed: "subscriptionStatus,trialPeriod",
        });
        return response.data;
    }

    async findUserDevice(filter) {
        const response = await this.deviceService.findUserDevice(filter);
        return response.data;
    }

    async updateUserDeviceDisabledStatus(id, isDisabled) {
        const response = await this.deviceService.updateUserDeviceDisabledStatus(id, isDisabled);
        return response.data;
    }
}

export default UserEditRouteStore;
