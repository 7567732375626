import React from "react";
import ContentLoader from "react-content-loader";

const LatestItemsLoaderTemplate = ({ rows = 5, props }) => {
    const screenwidth = window.innerWidth;
    let loaderWidth;

    if (screenwidth > 1200) {
        loaderWidth = (screenwidth - 300) / 2;
    } else if (screenwidth > 988) {
        loaderWidth = screenwidth - 250;
    } else {
        loaderWidth = screenwidth;
    }

    const items = [];
    let y = 60;

    for (let i = 0; i < rows; i++) {
        items.push(
            <React.Fragment key={i}>
                <rect x="0" y={y} rx="6" ry="6" width="83%" height="48" />
                <rect x="85%" y={y} rx="6" ry="6" width="15%" height="48" />
            </React.Fragment>
        );

        y += 60;
    }

    return (
        <ContentLoader
            speed={2}
            width="100%"
            height="100%"
            viewBox={`0 0 ${loaderWidth} 360`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            <rect x="0" y="5" rx="6" ry="6" width="15%" height="10" />

            <rect x="0" y="35" rx="6" ry="6" width="100%" height="2" />

            {items}
        </ContentLoader>
    );
};
export default LatestItemsLoaderTemplate;
