import React from "react";
import { DeviceMenuListTemplate } from "themes/administration/device-menu/components";

class DeviceMenuList extends React.Component {
    render() {
        return <DeviceMenuListTemplate {...this.props} />;
    }
}

export default DeviceMenuList;
