import React from "react";
import { PropTypes } from "prop-types";
import { DeviceMenuItem } from "administration/device-menu/components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FixedSizeList } from "react-window";
import { observer } from "mobx-react";

function DeviceMenuListTemplate({ store, t }) {
    const { deviceMenu, reorderMenu, setVirtualizedListRef } = store;

    return (
        <React.Fragment>
            <DragDropContext onDragEnd={reorderMenu}>
                <Droppable
                    droppableId="device-menu"
                    mode="virtual"
                    renderClone={(provided, snapshot, rubric) => (
                        <DeviceMenuItem
                            provided={provided}
                            isDragging={snapshot.isDragging}
                            item={deviceMenu[rubric.source.index]}
                            store={store}
                            index={rubric.source.index}
                            t={t}
                        />
                    )}
                >
                    {(provided) => (
                        <FixedSizeList
                            height={714}
                            width={"100%"}
                            itemCount={deviceMenu.length}
                            itemSize={69}
                            itemData={{
                                items: deviceMenu,
                                store: store,
                            }}
                            outerRef={provided.innerRef}
                            ref={setVirtualizedListRef}
                        >
                            {Row}
                        </FixedSizeList>
                    )}
                </Droppable>
            </DragDropContext>
        </React.Fragment>
    );
}

DeviceMenuListTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const Row = React.memo(({ data, index, style, t }) => {
    const { items, store } = data;
    const item = items[index];

    return (
        <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={store.submitting}>
            {(provided) => (
                <DeviceMenuItem provided={provided} style={style} item={item} index={index} store={store} t={t} />
            )}
        </Draggable>
    );
});

export default observer(DeviceMenuListTemplate);
