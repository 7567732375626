import React, { useRef, useState, useEffect } from "react";
import ContentLoader from "react-content-loader";

const SearchResultsEmptyLoaderTemplate = (props) => {
    const ref = useRef(null);
    const [loaderWidth, setLoaderWidth] = useState(0);

    useEffect(() => {
        setLoaderWidth(ref.current.offsetWidth);
    }, []);

    return (
        <div ref={ref}>
            <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox={`0 0 ${loaderWidth} 300`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                <rect x="0" y="15" rx="6" ry="6" width="15%" height="10" />
                <rect x="0" y="45" rx="6" ry="6" width="100%" height="2" />

                <rect x="25%" y="40%" rx="6" ry="6" width="50%" height="70" />

                <rect x="20%" y="70%" rx="6" ry="6" width="60%" height="15" />
            </ContentLoader>
        </div>
    );
};

export default SearchResultsEmptyLoaderTemplate;
