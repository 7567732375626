import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldInput } from "mrb/components/input";
import { MrbFieldCheckbox } from "mrb/components/checkbox";
import { MrbEditFormLayout } from "mrb/components/layout";
import { PasswordFieldInfo } from "common/components";
import { defaultTemplate } from "common/hoc";

function UserPasswordChangeTemplate({ userPasswordChangeViewStore, t }) {
    const { form } = userPasswordChangeViewStore;
    return (
        <div>
            <div className="u-mar--bottom--sml">
                <h2 className="u-type--base u-mar--bottom--sml">{t("ADMINISTRATION.USER.PASSWORD_CHANGE.TITLE")}</h2>
            </div>
            <MrbEditFormLayout form={form} t={t}>
                <MrbFieldInput
                    field={form.$("password")}
                    t={t}
                    classNameExtend="c-input--outline"
                    autoComplete="new-password"
                />
                <PasswordFieldInfo field={form.$("password")} />
                <MrbFieldInput
                    field={form.$("confirmPassword")}
                    t={t}
                    classNameExtend="c-input--outline"
                    autoComplete="new-password"
                />
                <MrbFieldCheckbox
                    field={form.$("sendMailNotification")}
                    t={t}
                />
            </MrbEditFormLayout>
        </div>
    );
}

UserPasswordChangeTemplate.propTypes = {
    userPasswordChangeViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(UserPasswordChangeTemplate);
