import React from "react";
import { DeviceMenuItemTemplate } from "themes/administration/device-menu/components";

class DeviceMenuItem extends React.Component {
    render() {
        return <DeviceMenuItemTemplate {...this.props} />;
    }
}

export default DeviceMenuItem;
