import React from "react";
import { DeviceMenuLatestItemsTemplate } from "themes/administration/device-menu/components";

class DeviceMenuLatestItems extends React.Component {
    render() {
        return <DeviceMenuLatestItemsTemplate {...this.props} />;
    }
}

export default DeviceMenuLatestItems;
