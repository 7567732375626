import React from "react";
import { UserDeviceCellTemplate } from "themes/administration/user/components";
import { setCurrentView } from "mrb/core";
import { UserDeviceCellViewStore } from "administration/user/stores";

@setCurrentView(
    (rootStore, componentProps) => new UserDeviceCellViewStore(rootStore, componentProps),
    "userDeviceCellViewStore"
)
class UserDeviceCell extends React.Component {
    render() {
        return <UserDeviceCellTemplate {...this.props} />;
    }
}

export default UserDeviceCell;
