import { action, computed, makeObservable, runInAction } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { MrbGridViewStore } from "mrb/components/grid";
import { PodcastDeviceGridItemDecorator } from "administration/user/models";
import { find } from "lodash";

class PodcastDeviceViewStore extends MrbBaseListViewStore {
    @computed get deviceId() {
        return this.userDeviceSettingsViewStore.selectedDeviceId;
    }

    @computed get isInitalized() {
        return this.gridStore.dataInitialized;
    }

    @computed get isActivePodcastTab() {
        return this.userDeviceSettingsViewStore.tabsStore.activeTabIndex === 1;
    }

    constructor(rootStore, userDeviceSettingsViewStore) {
        super(rootStore, {});
        makeObservable(this);
        this.userDeviceSettingsViewStore = userDeviceSettingsViewStore;
        this.routeStore = this.userDeviceSettingsViewStore.routeStore;

        this.reaction(
            () => this.deviceId,
            () => {
                if (this.isActivePodcastTab) {
                    this.queryUtility.fetch();
                }
            }
        );

        this.reaction(
            () => this.queryUtility.filter.search,
            () => {
                if (this.isActivePodcastTab) {
                    this.queryUtility.fetch();
                }
            },
            {
                delay: 300,
            }
        );

        this.setGridStore(
            new MrbGridViewStore(this.rootStore, this.queryUtility, {
                generateKey: (item) => item.id,
                mapper: (item) => new PodcastDeviceGridItemDecorator(item),
            })
        );
    }

    find(params) {
        params.deviceId = this.deviceId;
        return this.routeStore.findPodcastDevice(params);
    }

    @action.bound
    async toggleIsTurnedOn(podcastId, value) {
        const podcast = find(this.gridStore.data, (item) => item.id === podcastId);
        if (podcast) {
            try {
                const response = await this.routeStore.updatePodcastDeviceTurnedOn(this.deviceId, podcastId, value);
                runInAction(() => {
                    podcast.userDevicePodcast = response;
                });
            } catch (err) {
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to update user device status.",
                    err
                );
            }
        }
    }
}

export default PodcastDeviceViewStore;
