import React from "react";
import ContentLoader from "react-content-loader";

const DeviceDowntimeLoaderTemplate = ({
    row = 7,
    col = 24,
    props,
}) => {
    const screenwidth = window.innerWidth;
    const itemsTime = [];
    const itemsCheck = [];
    const itemsDays = [];
    const xSpace =  screenwidth > 1100 ? (screenwidth - 250) / col : 51;

    let dateX = 100;

    let checkX = 100;
    let checkY = 155;

    let daysY = 165;

    // DATE
    for (let i = 0; i < col; i++) {
        itemsTime.push(
            <React.Fragment key={i}>
                <rect x={dateX} y="130" rx="6" ry="6" width="28" height="7" />
            </React.Fragment>
        );

        dateX += xSpace;
    }

    // DAYS
    for (let i = 0; i < row; i++) {
        itemsDays.push(
            <React.Fragment key={i}>
                <rect x="0" y={daysY} rx="6" ry="6" width="70" height="7" />
            </React.Fragment>
        );

        daysY += 35;
    }

    // CHECKBOX
    for (let i = 0; i < row; i++) {
        for (let j = 0; j < col; j++) {
            itemsCheck.push(
                <React.Fragment key={`i:${i}_j:${j}`}>
                    <rect
                        x={checkX}
                        y={checkY}
                        rx="6"
                        ry="6"
                        width="28"
                        height="28"
                    />
                </React.Fragment>
            );

            checkX += xSpace;
        }

        checkY += 35;
        checkX = 100;
    }

    return (
        <ContentLoader
            speed={2}
            width="100%"
            height="100%"
            viewBox={`0 0 ${screenwidth > 992 ? screenwidth - 200 : screenwidth} 400`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            preserveAspectRatio="none"
            {...props}
        >
            <rect x="0" y="10" rx="6" ry="6" width="120" height="12" />

            <rect x="0" y="50" rx="6" ry="6" width="80" height="5" />
            <rect x="0" y="70" rx="6" ry="6" width="250" height="42" />

            {itemsTime}
            {itemsCheck}
            {itemsDays}
        </ContentLoader>
    );
};
export default DeviceDowntimeLoaderTemplate;
