import React from "react";
import { UserPodcastExpirationCellTemplate } from "themes/administration/user/components";
import { UserPodcastExpirationCellViewStore } from "administration/user/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new UserPodcastExpirationCellViewStore(rootStore, componentProps),
    "userPodcastExpirationCellViewStore"
)
class UserPodcastExpirationCell extends React.Component {
    render() {
        return <UserPodcastExpirationCellTemplate {...this.props} />;
    }
}

export default UserPodcastExpirationCell;
