import React from "react";
import { AdminUserSubscriptionSettingsTemplate } from "themes/administration/user/components";
import { AdminUserSubscriptionSettingsViewStore } from "administration/user/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new AdminUserSubscriptionSettingsViewStore(rootStore, componentProps),
    "adminUserSubscriptionSettingsViewStore"
)
class AdminUserSubscriptionSettings extends React.Component {
    render() {
        return <AdminUserSubscriptionSettingsTemplate {...this.props} />;
    }
}

export default AdminUserSubscriptionSettings;
