import React from "react";
import ContentLoader from "react-content-loader";

const DeviceListLoaderTemplate = ({ height = "100%", rows = 16, props }) => {
    const screenwidth = window.innerWidth;
    let loaderWidth;

    if (screenwidth > 1199) {
        loaderWidth = (screenwidth - 400) / 2;
    } else if (screenwidth > 988) {
        loaderWidth = screenwidth - 200;
    } else {
        loaderWidth = screenwidth;
    }

    const items = [];
    let y = 0;

    for (let i = 0; i < rows; i++) {
        items.push(
            <React.Fragment key={i}>
                <rect x="0" y={y} rx="6" ry="6" width="83%" height="40" />
                <rect x="85%" y={y} rx="6" ry="6" width="15%" height="40" />
            </React.Fragment>
        );

        y += 43;
    }

    return (
        <ContentLoader
            speed={2}
            width="100%"
            height="100%"
            viewBox={`0 0 ${loaderWidth} 680`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            {items}
        </ContentLoader>
    );
};
export default DeviceListLoaderTemplate;
