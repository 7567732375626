import { action, computed, makeObservable, observable } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { MrbFieldSelectStore } from "mrb/components/select";
import { SystemUserSubscriptionForm } from "administration/user/forms";
import { applicationDefaults, subscriptionStatuses, nakiRadioProductCategoryAbbreviations } from "common/constants";
import { dateFormatter } from "mrb/common/formatting";
import { find, isNil, orderBy, findIndex } from "lodash";
import moment from "moment";

const cycleTypes = {
    weeks: "weeks",
    months: "months",
};

const activeSubscriptionStatuses = [
    subscriptionStatuses.active,
    subscriptionStatuses.pastDue,
    subscriptionStatuses.trial,
];

class AdminUserSubscriptionSettingsViewStore extends MrbBaseViewStore {
    @observable isSubmitting = false;
    @observable userSubscriptionInfo;
    userId;

    @computed get paidSubscriptionExist() {
        return (
            !isNil(this.userSubscriptionInfo) &&
            !isNil(this.userSubscriptionInfo.subscriptions) &&
            this.userSubscriptionInfo.subscriptions.length > 0
        );
    }

    @computed get activeSubscription() {
        if (this.userSubscriptionInfo) {
            return find(
                orderBy(this.userSubscriptionInfo.subscriptions, (s) => s.startDate, "desc"),
                (s) =>
                    findIndex(activeSubscriptionStatuses, (statusAbrv) => s.subscriptionStatus.abrv === statusAbrv) !==
                    -1
            );
        }
        return null;
    }

    @computed get pendingSubscription() {
        if (this.userSubscriptionInfo) {
            return find(
                this.userSubscriptionInfo.subscriptions,
                (s) => s.subscriptionStatus.abrv === subscriptionStatuses.pending
            );
        }
        return null;
    }

    @computed get isFormDisabled() {
        return this.paidSubscriptionExist || this.isSubmitting;
    }

    cycleTypeOptions = [
        {
            label: "Week(s)",
            value: cycleTypes.weeks,
        },
        {
            label: "Month(s)",
            value: cycleTypes.months,
        },
    ];

    constructor(rootStore, { routeStore, userId }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;
        this.userId = userId;

        this.activateSubscription = this.activateSubscription.bind(this);
        this.cancelInternalSubscription = this.cancelInternalSubscription.bind(this);
        this.cancelBundleSubscription = this.cancelBundleSubscription.bind(this);
        this.reloadSubscription = this.reloadSubscription.bind(this);

        this.form = new SystemUserSubscriptionForm({
            onSuccess: (form) => {
                const data = form.values();
                this.activateSubscription(data);
            },
        });

        this.subscriptionSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: false,
                placeholder: "Select subscription",
                actions: {
                    find: () => {
                        return this.routeStore.getAllSubscriptionProducts();
                    },
                },
            },
            this.form.$("subscriptionId")
        );

        this.cycleSelectStore = new MrbFieldSelectStore(
            {
                placeholder: "Select subscription",
                isClearable: false,
                textKey: "label",
                valueKey: "value",
            },
            this.form.$("cycleType"),
            this.cycleTypeOptions
        );
    }

    async onInit() {
        await this.fetchUserSubscription(this.userId);
    }

    async reloadSubscription() {
        await this.fetchUserSubscription(this.userId);
    }

    async fetchUserSubscription(userId) {
        try {
            const data = await this.routeStore.getUserSubscriptionInfo(userId);
            this.setUserSubscriptionInfo(data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "Unexpected error occurred while trying load user subscription. Please contact the support team.",
                err
            );
        }
    }

    @action.bound
    setUserSubscriptionInfo(data) {
        this.userSubscriptionInfo = data;
    }

    activateSubscription(data) {
        const endOfSubscription = moment
            .utc()
            .add(data.numberOfCycles, data.cycleType === cycleTypes.weeks ? "weeks" : "months")
            .toISOString();
        const subscriptionPlan = find(
            this.subscriptionSelectStore.originalItems.items,
            (item) => item.id === data.subscriptionId
        );
        const isPlural = data.numberOfCycles > 1;
        const cycle =
            data.cycleType === cycleTypes.weeks ? (isPlural ? "weeks" : "week") : isPlural ? "months" : "month";

        this.rootStore.confirmDialogStore.showConfirm({
            message: "Activate subscription?",
            description: `You will activate ${subscriptionPlan.name} subscription for ${
                data.numberOfCycles
            } ${cycle}, until ${dateFormatter.format(endOfSubscription, {
                format: applicationDefaults.formats.date,
            })}.`,
            yesLabel: "Activate",
            onConfirm: async () => {
                try {
                    this.setIsSubmitting(true);
                    await this.routeStore.activateSubscription({
                        userId: this.userId,
                        subscriptionPlanId: data.subscriptionId,
                        subscriptionEndDate: endOfSubscription,
                    });
                    this.reloadSubscription();
                } catch (err) {
                    this.rootStore.notificationStore.error(
                        "Unexpected error occurred while trying to activate subscription. Please contact the support team.",
                        err
                    );
                } finally {
                    this.setIsSubmitting(false);
                }
            },
        });
    }

    cancelInternalSubscription() {
        if (!isNil(this.activeSubscription) && this.activeSubscription.isInternal) {
            this.rootStore.confirmDialogStore.showConfirm({
                message: "Cancel subscription?",
                description: `You will immediately cancel the user's internal subscription.`,
                yesLabel: "Cancel",
                onConfirm: async () => {
                    try {
                        this.setIsSubmitting(true);
                        await this.routeStore.cancelInternalSubscription(this.activeSubscription.id);
                        this.reloadSubscription();
                    } catch (err) {
                        this.rootStore.notificationStore.error(
                            "Unexpected error occurred while trying to cancel subscription. Please contact the support team.",
                            err
                        );
                    } finally {
                        this.setIsSubmitting(false);
                    }
                },
            });
        }
    }

    cancelBundleSubscription() {
        if (
            !isNil(this.activeSubscription) &&
            this.activeSubscription.product.category.abrv === nakiRadioProductCategoryAbbreviations.subscriptionBundle
        ) {
            this.rootStore.confirmDialogStore.showConfirm({
                message: "Cancel subscription?",
                description: `You will immediately cancel the user's bundle subscription.`,
                yesLabel: "Cancel",
                onConfirm: async () => {
                    try {
                        this.setIsSubmitting(true);
                        await this.routeStore.cancelBundleSubscription(this.activeSubscription.id);
                        this.reloadSubscription();
                    } catch (err) {
                        this.rootStore.notificationStore.error(
                            "Unexpected error occurred while trying to cancel subscription. Please contact the support team.",
                            err
                        );
                    } finally {
                        this.setIsSubmitting(false);
                    }
                },
            });
        }
    }

    @action.bound
    setIsSubmitting(value) {
        this.isSubmitting = value;
    }
}

export default AdminUserSubscriptionSettingsViewStore;
