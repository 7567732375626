import React, { useState, useCallback } from "react";
import ContentLoader from "react-content-loader";

const UserSubscriptionLoaderTemplate = (props) => {
    const [loaderWidth, setLoaderWidth] = useState(0);

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    return (
        <div className={`${props.formLoader ? "c-card--primary" : "c-payment__card c-payment__card--lrg"}`} ref={ref}>
            <ContentLoader
                speed={2}
                width={loaderWidth}
                height={props.formLoader ? 166 : 255}
                viewBox={`0 0 ${loaderWidth} ${props.formLoader ? 166 : 255}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
            >
                {props.formLoader ? (
                    <React.Fragment>
                        <rect x="0" y="0" rx="6" ry="6" width="132" height="12" />
                        <rect x="0" y="24" rx="6" ry="6" width="33%" height="43" />

                        <rect x="34%" y="0" rx="6" ry="6" width="132" height="12" />
                        <rect x="34%" y="24" rx="6" ry="6" width="33%" height="43" />

                        <rect x="68%" y="0" rx="6" ry="6" width="132" height="12" />
                        <rect x="68%" y="24" rx="6" ry="6" width="33%" height="43" />

                        <rect x="0" y="90" rx="6" ry="6" width="100%" height="1" />
                        <rect x="0" y="120" rx="6" ry="6" width="120" height="43" />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <rect x="0" y="24" rx="6" ry="6" width="32" height="32" />

                        <rect x="60" y="0" rx="6" ry="6" width="100" height="12" />
                        <rect x="60" y="30" rx="6" ry="6" width="150" height="20" />
                        <rect x="60" y="60" rx="6" ry="6" width="70" height="8" />

                        <rect x="0" y="95" rx="6" ry="6" width="100%" height="1" />

                        <rect x="0" y="115" rx="6" ry="6" width="170" height="12" />

                        <rect x="0" y="140" rx="6" ry="6" width="100" height="12" />
                        <rect x={loaderWidth - 90} y="140" rx="6" ry="6" width="90" height="12" />

                        <rect x="0" y="175" rx="6" ry="6" width="100" height="12" />
                        <rect x={loaderWidth - 90} y="175" rx="6" ry="6" width="90" height="12" />

                        <rect x="0" y="215" rx="6" ry="6" width="100%" height="40" />
                    </React.Fragment>
                )}
            </ContentLoader>
        </div>
    );
};

export default UserSubscriptionLoaderTemplate;
