import { action, computed, makeObservable, runInAction } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { MrbGridViewStore } from "mrb/components/grid";
import { find } from "lodash";

class UserPodcastExpirationViewStore extends MrbBaseListViewStore {
    get userId() {
        return this.userContentExpirationViewStore.userId;
    }

    @computed get isInitalized() {
        return this.gridStore.dataInitialized;
    }

    constructor(rootStore, userContentExpirationViewStore) {
        super(rootStore, {
            queryConfig: {
                disableUpdateQueryParams: true,
            },
        });
        makeObservable(this);
        this.userContentExpirationViewStore = userContentExpirationViewStore;
        this.routeStore = this.userContentExpirationViewStore.routeStore;

        this.setGridStore(
            new MrbGridViewStore(this.rootStore, this.queryUtility, {
                generateKey: (item) => item.podcastId,
            })
        );

        this.reaction(
            () => this.queryUtility.filter.search,
            () => {
                this.queryUtility.fetch();
            },
            {
                delay: 300,
            }
        );
    }

    find(params) {
        params.userId = this.userId;
        return this.routeStore.findUserPodcastExpiration(params);
    }

    @action.bound
    async createExpirationDate(podcastId, value) {
        const podcast = find(this.gridStore.data, (item) => item.podcastId === podcastId);
        if (podcast) {
            try {
                this.rootStore.mainLoaderStore.suspend();
                const data = {
                    podcastId: podcast.podcastId,
                    userId: this.userId,
                    expirationDate: value,
                };
                const item = await this.routeStore.createUserPodcastExpiration(data);
                runInAction(() => {
                    podcast.userPodcastExpirationId = item.id;
                    podcast.expirationDate = value;
                });
            } catch (err) {
                this.rootStore.notificationStore.error(
                    "An unexpected error occurred while trying to update podcast expiration date. Please contact the support team."
                );
            } finally {
                this.rootStore.mainLoaderStore.resume();
            }
        }
        return podcast;
    }

    @action.bound
    async updateExpirationDate(podcastId, value) {
        const podcast = find(this.gridStore.data, (item) => item.podcastId === podcastId);
        if (podcast) {
            try {
                this.rootStore.mainLoaderStore.suspend();
                const data = {
                    id: podcast.userPodcastExpirationId,
                    podcastId: podcast.podcastId,
                    userId: this.userId,
                    expirationDate: value,
                };
                await this.routeStore.updateUserPodcastExpiration(data);
                podcast.expirationDate = value;
            } catch (err) {
                this.rootStore.notificationStore.error(
                    "An unexpected error occurred while trying to update podcast expiration date. Please contact the support team."
                );
            } finally {
                this.rootStore.mainLoaderStore.resume();
            }
        }
        return podcast;
    }

    @action.bound
    async deleteExpirationDate(podcastId) {
        const podcast = find(this.gridStore.data, (item) => item.podcastId === podcastId);
        if (podcast) {
            try {
                this.rootStore.mainLoaderStore.suspend();
                await this.routeStore.deleteUserPodcastExpiration(podcast.userPodcastExpirationId);
                runInAction(() => {
                    podcast.userPodcastExpirationId = null;
                });
            } catch (err) {
                this.rootStore.notificationStore.error(
                    "An unexpected error occurred while trying to update podcast expiration date. Please contact the support team."
                );
            } finally {
                this.rootStore.mainLoaderStore.resume();
            }
        }
        return podcast;
    }
}

export default UserPodcastExpirationViewStore;
