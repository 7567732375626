import React from "react";
import { DeviceMenuEditPageLayoutTemplate } from "themes/administration/device-menu/components";

class DeviceMenuEditPageLayout extends React.Component {
    render() {
        return <DeviceMenuEditPageLayoutTemplate {...this.props} />;
    }
}

export default DeviceMenuEditPageLayout;
