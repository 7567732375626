import { MrbBaseRouteStore } from "mrb/core";
import { DeviceService } from "common/services";
import { StationService, PodcastService } from "common/services";

class UserDeviceSettingsRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = this.rootStore.createApplicationService(DeviceService);

        this.stationService = rootStore.createApplicationService(StationService);
        this.podcastService = rootStore.createApplicationService(PodcastService);
    }

    async findUserDevices(params) {
        const response = await this.service.findUserDevice(params);
        return response.data;
    }

    async findStationDevice(params) {
        const response = await this.stationService.findDeviceStations(params);
        return response.data;
    }

    async findPodcastDevice(params) {
        const response = await this.podcastService.findDevicePodcasts(params);
        return response.data;
    }

    async updateStationDeviceTurnedOn(deviceId, stationId, isTurnedOn) {
        const response = await this.stationService.updateStationTurnedOnStatus(deviceId, stationId, isTurnedOn);
        return response.data;
    }

    async updatePodcastDeviceTurnedOn(deviceId, podcastId, isTurnedOn) {
        const response = await this.podcastService.updatePodcastTurnedOnStatus(deviceId, podcastId, isTurnedOn);
        return response.data;
    }

    async getUser(userId) {
        const response = await this.rootStore.application.baasic.membershipModule.user.get(userId);
        return response.data;
    }
}

export default UserDeviceSettingsRouteStore;
