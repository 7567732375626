import React from "react";
import { UserPasswordChangeTemplate } from "themes/administration/user/components";
import { UserPasswordChangeViewStore } from "administration/user/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new UserPasswordChangeViewStore(rootStore, componentProps),
    "userPasswordChangeViewStore"
)
class UserPasswordChange extends React.Component {
    render() {
        return <UserPasswordChangeTemplate {...this.props} />;
    }
}

export default UserPasswordChange;
