import React from "react";
import { UserStationExpirationCellTemplate } from "themes/administration/user/components";
import { UserStationExpirationCellViewStore } from "administration/user/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new UserStationExpirationCellViewStore(rootStore, componentProps),
    "userStationExpirationCellViewStore"
)
class UserStationExpirationCell extends React.Component {
    render() {
        return <UserStationExpirationCellTemplate {...this.props} />;
    }
}

export default UserStationExpirationCell;
