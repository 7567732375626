import { LocalizedForm } from "common/localization";

export default class SystemUserSubscriptionForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "subscriptionId",
                    label: "ADMINISTRATION.USER.EDIT.SUBSCRIPTION.FIELDS.SUBSCRIPTION_LABEL",
                    placeholder: "ADMINISTRATION.USER.EDIT.SUBSCRIPTION.FIELDS.SUBSCRIPTION_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "numberOfCycles",
                    label: "ADMINISTRATION.USER.EDIT.SUBSCRIPTION.FIELDS.NUMBER_OF_CYCLES_LABEL",
                    placeholder: "ADMINISTRATION.USER.EDIT.SUBSCRIPTION.FIELDS.NUMBER_OF_CYCLES_PLACEHOLDER",
                    rules: "required|numeric|min:1",
                },
                {
                    name: "cycleType",
                    label: "ADMINISTRATION.USER.EDIT.SUBSCRIPTION.FIELDS.CYCLE_TYPE_LABEL",
                    placeholder: "ADMINISTRATION.USER.EDIT.SUBSCRIPTION.FIELDS.CYCLE_TYPE_PLACEHOLDER",
                    rules: "required|string",
                },
            ],
        };
    }
}
