import { runInAction, observable, action, makeObservable } from "mobx";
import { MrbBaseEditViewStore } from "mrb/core";
import { UserEditForm } from "administration/user/forms";
import { localizationService } from "common/localization";
import { nakiRadioRoles } from "common/constants";
import { applicationErrorCodes } from "common/constants";
import { MrbModalParams } from "mrb/common/models";
import { filter, merge, some, find, map } from "lodash";

class UserEditViewStore extends MrbBaseEditViewStore {
    administratorRole = null;
    producerRole = null;
    @observable guestEmail = null;
    @observable producers = [];

    @observable artistInfo = null;

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: id,
            name: "user",
            FormClass: UserEditForm,
            autoFocusField: "firstName",
            navigateBack: "master.administration.user.list",
            successCreateNotification: null,
            successUpdateNotification: null,
            redirectOnUpdateSuccess: () => this.rootStore.routerStore.goTo("master.administration.user.list"),
        });
        makeObservable(this);
        this.routeStore = routeStore;
        this.isAdminItself = id === this.rootStore.userStore.user.id;
        this.passwordResetModal = new MrbModalParams();

        this.reaction(
            () => this.form.$("isAdministrator").value,
            (isAdministrator) => {
                if (isAdministrator) {
                    this.form.$("isProducer").set(false);
                }
            }
        );
    }

    get(id) {
        return this.routeStore.getResource(id);
    }

    update(data) {
        const resource = merge({}, this.item);
        resource.firstName = data.firstName;
        resource.lastName = data.lastName;
        resource.email = data.email;
        if (data.isAdministrator && !some(resource.roles, (role) => role.id === this.administratorRole.id)) {
            resource.roles.push(this.administratorRole);
        } else if (!data.isAdministrator && some(resource.roles, (role) => role.id === this.administratorRole.id)) {
            resource.roles = filter(resource.roles, (role) => role.id !== this.administratorRole.id);
        }

        return this.routeStore.update(resource);
    }

    async getResource(id) {
        const [user, roles] = await Promise.all([this.get(id), this.routeStore.getRoles()]);
        this.setGuestEmail(user.guestEmail);
        this.setArtistInfo({
            artistRegisterMessage: user.producerUserAccount?.artistRegisterMessage,
            producerType: user.producerUserAccount?.producerType,
            phoneNumber: user.producerUserAccount?.phoneNumber,
        });
        runInAction(() => {
            this.administratorRole = find(roles, (role) => role.name === nakiRadioRoles.administratorRoleName);
            this.producerRole = find(roles, (role) => role.name === nakiRadioRoles.producerRoleName);
            this.setItem(user);
            const isProducer = user.roles && some(user.roles, (role) => role.id === this.producerRole.id);
            const isAdministrator = user.roles && some(user.roles, (role) => role.id === this.administratorRole.id);
            if (isProducer) {
                map(user.producerUserAccount?.assignedProducerUserAccounts, (assignedProducerUserAccount) => {
                    const producer = assignedProducerUserAccount.producer;
                    this.producers.push({
                        producerName: producer.name,
                        producerId: producer.id,
                    });
                });
            }
            this.updateForm({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                isProducer: isProducer,
                isAdministrator: isAdministrator,
            });
        });
    }

    onUpdateError(error) {
        if (error.data.errorCode === applicationErrorCodes.producer.administratorConflict) {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.USER.EDIT.ERROR.PRODUCER_ADMIN_CONFLICT"),
                error
            );
        } else if (error.data.errorCode === applicationErrorCodes.producer.emailAlreadyExists) {
            this.form.$("email").invalidate("User with specified E-mail address already exists.");
        } else {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.USER.EDIT.ERROR.GENERIC_MESSAGE"),
                error
            );
        }
    }

    disapproveUser = () => {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Do you want to disapprove user?",
            onConfirm: async () => {
                await this.routeStore.disapprove(this.item);
                this.item.isApproved = false;
            },
            yesLabel: "Disapprove",
            noLabel: "Cancel",
            description: "A disapproved user won't be able to sign and use the application anymore.",
        });
    };

    approveUser = () => {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Do you want to approve user?",
            onConfirm: async () => {
                await this.routeStore.approve(this.item);
                this.item.isApproved = true;
            },
            yesLabel: "Approve",
            noLabel: "Cancel",
            description: "An approved user will be able to sign in and use the application.",
        });
    };

    lockUser = () => {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Do you want to lock user?",
            onConfirm: async () => {
                await this.routeStore.lock(this.item);
                this.item.isLockedOut = true;
            },
            yesLabel: "Lock",
            noLabel: "Cancel",
            description: "A locked user won't be able to sign in to the application using his current password.",
        });
    };

    unlockUser = () => {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Do you want to unlock user?",
            onConfirm: async () => {
                await this.routeStore.unlock(this.item);
                this.item.isLockedOut = false;
            },
            yesLabel: "Unlock",
            noLabel: "Cancel",
            description: "An unlocked user will be able to sign in to the application using his current password.",
        });
    };

    changePassword = () => {
        this.passwordResetModal.open({
            user: this.item,
            routeStore: this.routeStore,
        });
    };

    @action.bound
    setGuestEmail(email) {
        this.guestEmail = email;
    }

    @action.bound
    setArtistInfo(value) {
        this.artistInfo = value;
    }
}

export default UserEditViewStore;
