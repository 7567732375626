import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldSelect } from "mrb/components/select";
import { MrbFieldNumericInput } from "mrb/components/input";
import { dateFormatter } from "mrb/common/formatting";
import { applicationDefaults, subscriptionPlans, subscriptionStatuses } from "common/constants";
import { isSubscriptionBundle } from "common/utils";
import { MrbContentLoader } from "mrb/components/loader";
import { UserSubscriptionLoader } from "administration/user/components";
import {
    SubscriptionPrice,
    NakiRadioPremiumBadge,
    NakiNigunPremiumBadge,
    NakiRadioFreeBadge,
} from "application/payment/components";
import { MrbButton } from "mrb/components/button";
import { mergeCss } from "mrb/common/utils";
import { observer } from "mobx-react";
import { isNil } from "lodash";

function AdminUserSubscriptionSettingsTemplate({ adminUserSubscriptionSettingsViewStore, t }) {
    const { subscriptionSelectStore, cycleSelectStore, paidSubscriptionExist, isFormDisabled, form, loaderStore } =
        adminUserSubscriptionSettingsViewStore;

    return (
        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <UserSubscriptionLoader formLoader />}>
            {() => (
                <div className="u-animation--fadeIn">
                    {paidSubscriptionExist ? (
                        <PaidSubscriptionInfo store={adminUserSubscriptionSettingsViewStore} />
                    ) : (
                        <div className="c-card--primary">
                            <div className="row">
                                <div className="col col-sml-12 col-lrg-6 col-huge-4">
                                    <MrbFieldSelect
                                        disabled={isFormDisabled}
                                        store={subscriptionSelectStore}
                                        classNameExtend="c-dropdown--dark c-dropdown--base"
                                        fieldClassNameExtend="u-mar--bottom--tny"
                                        t={t}
                                    />
                                </div>

                                <div className="col col-sml-12 col-lrg-6 col-huge-4">
                                    <MrbFieldNumericInput
                                        disabled={isFormDisabled}
                                        classNameOverride="c-input c-input--med c-input--outline"
                                        fieldClassNameExtend="u-mar--bottom--tny"
                                        precision={0}
                                        t={t}
                                        field={form.$("numberOfCycles")}
                                    />
                                </div>
                                <div className="col col-sml-12 col-lrg-6 col-huge-4">
                                    <MrbFieldSelect
                                        disabled={isFormDisabled}
                                        store={cycleSelectStore}
                                        classNameExtend="c-dropdown--dark c-dropdown--base"
                                        fieldClassNameExtend="u-mar--bottom--tny"
                                        t={t}
                                    />
                                </div>
                            </div>
                            <div className="u-separator--primary"></div>
                            <MrbButton
                                label="ADMINISTRATION.USER.EDIT.SUBSCRIPTION.BUTTONS.ACTIVATE"
                                t={t}
                                disabled={isFormDisabled}
                                onClick={form.onSubmit}
                            />
                        </div>
                    )}
                </div>
            )}
        </MrbContentLoader>
    );
}

AdminUserSubscriptionSettingsTemplate.propTypes = {
    adminUserSubscriptionSettingsViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function PaidSubscriptionInfo({ store }) {
    const { activeSubscription, pendingSubscription, cancelInternalSubscription, cancelBundleSubscription } = store;
    return (
        <div className="c-payment__list c-payment__list--plan c-payment__list--plan--secondary">
            {activeSubscription && (
                <SubscriptionHeader
                    subscription={activeSubscription}
                    cancelInternalSubscription={cancelInternalSubscription}
                    cancelBundleSubscription={cancelBundleSubscription}
                />
            )}
            {!isSubscriptionBundle(activeSubscription.product.category) && pendingSubscription ? (
                <React.Fragment>
                    <div className="u-display--flex u-display--flex--ai--center">
                        <i className="u-icon u-icon--lrg u-icon--arrow-right--secondary"></i>
                    </div>
                    <SubscriptionHeader
                        subscription={pendingSubscription}
                        cancelInternalSubscription={cancelInternalSubscription}
                        cancelBundleSubscription={cancelBundleSubscription}
                    />
                </React.Fragment>
            ) : null}
        </div>
    );
}

function SubscriptionHeader({ subscription, cancelInternalSubscription, cancelBundleSubscription }) {
    let premiumBadge = null;
    let activeClassName = "";
    switch (subscription.product.slug) {
        case subscriptionPlans.nakiRadioPremium:
            premiumBadge = <NakiRadioPremiumBadge iconSize="base" />;
            activeClassName = "primary";
            break;
        case subscriptionPlans.nakiNigun:
            premiumBadge = <NakiNigunPremiumBadge iconSize="base" />;
            activeClassName = "tertiary";
            break;
        default:
            premiumBadge = <NakiRadioFreeBadge iconSize="base" />;
            activeClassName = "secondary";
            break;
    }
    const isPremiumSubscriptionTrial = subscription.subscriptionStatus.abrv === subscriptionStatuses.trial;

    let subscriptionStatus = "";
    let isTrial = false;
    switch (subscription.subscriptionStatus.abrv) {
        case subscriptionStatuses.pastDue:
            subscriptionStatus = "tertiary";
            break;
        case subscriptionStatuses.active:
            subscriptionStatus = "success";
            break;
        case subscriptionStatuses.trial:
            isTrial = true;
            subscriptionStatus = "secondary";
            break;
        default:
            break;
    }

    return (
        <div className={`c-payment__card c-payment__card--lrg c-payment__card--${activeClassName}`}>
            <div>
                <div className="u-mar--bottom--sml">
                    <div className="u-display--flex u-display--flex--ai--center">
                        {premiumBadge}
                        <div className="u-width--100 u-mar--left--med">
                            <p>{subscription.product.name}</p>
                            <p className="u-type--med u-type--wgt--bold u-mar--top--tny">
                                {isTrial || subscription.amount === 0 ? (
                                    <span>Free</span>
                                ) : (
                                    <SubscriptionPrice price={subscription.amount} />
                                )}
                            </p>
                            <p
                                className={mergeCss(
                                    "u-type--sml u-type--wgt--bold",
                                    `u-type--color--${subscriptionStatus}`
                                )}
                            >
                                {subscription.subscriptionStatus.name}
                            </p>
                        </div>
                    </div>

                    <div className="u-separator--primary"></div>

                    <div>
                        {subscription.isInternal && (
                            <div className="u-mar--bottom--tny">
                                <p className="u-type--color--note">Internally activated subscription</p>
                            </div>
                        )}
                        {isSubscriptionBundle(subscription.product.category) && (
                            <div className="u-mar--bottom--tny">
                                <p className="u-type--color--note">Subscription Bundle</p>
                            </div>
                        )}
                        {subscription.startDate && (
                            <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-mar--bottom--tny">
                                <span className="u-type--base">Start Date:</span>

                                <p>
                                    {dateFormatter.format(subscription.startDate, {
                                        format: applicationDefaults.formats.date,
                                    })}
                                </p>
                            </div>
                        )}
                        <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-mar--bottom--med">
                            {isNil(subscription.endDate) ? (
                                <React.Fragment>
                                    {!isNil(subscription.nextBillingDate) && (
                                        <React.Fragment>
                                            <span className="u-type--base">
                                                {isPremiumSubscriptionTrial
                                                    ? "Start Billing Date:"
                                                    : "Next Billing Date:"}
                                            </span>
                                            <p>
                                                {dateFormatter.format(subscription.nextBillingDate, {
                                                    format: applicationDefaults.formats.date,
                                                })}
                                            </p>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <span className="u-type--base">
                                        {isSubscriptionBundle(subscription.product.category)
                                            ? "End of Obligated Period:"
                                            : "End Date:"}
                                    </span>
                                    <p>
                                        {dateFormatter.format(subscription.endDate, {
                                            format: applicationDefaults.formats.date,
                                        })}
                                    </p>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div>
                {subscription.isInternal && (
                    <MrbButton
                        classNameExtend="c-btn--full"
                        onClick={cancelInternalSubscription}
                        label="Cancel Subscription"
                    />
                )}
                {isSubscriptionBundle(subscription.product.category) && (
                    <MrbButton
                        classNameExtend="c-btn--full"
                        onClick={cancelBundleSubscription}
                        label="Cancel Bundle Subscription"
                    />
                )}
            </div>
        </div>
    );
}

export default observer(AdminUserSubscriptionSettingsTemplate);
