import React from "react";
import { DeviceMenuTypeIconTemplate } from "themes/administration/device-menu/components";

class DeviceMenuTypeIcon extends React.Component {
    render() {
        return <DeviceMenuTypeIconTemplate {...this.props} />;
    }
}

export default DeviceMenuTypeIcon;
