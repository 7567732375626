import React from "react";
import PodcastDeviceCellTemplate from "themes/administration/user/components/PodcastDeviceCellTemplate";
import { PodcastDeviceCellViewStore } from "administration/user/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PodcastDeviceCellViewStore(rootStore, componentProps),
    "podcastDeviceCellViewStore"
)
class PodcastDeviceCell extends React.Component {
    render() {
        return <PodcastDeviceCellTemplate {...this.props} />;
    }
}

export default PodcastDeviceCell;
