import React from "react";
import { PropTypes } from "prop-types";
import { nakiRadioSystemTypes } from "common/constants";
import { mergeCss } from "baasic-react-bootstrap/src/common";

function DeviceGridCellTemplate({ item, actions, onCellClick }) {
    const handleDeviceGridCell = () => {
        if (item.isDisabled) return;
        onCellClick(item);
    };

    return (
        <div
            onClick={handleDeviceGridCell}
            className={mergeCss("c-device__card c-device__card--sml", item.isDisabled && "isDisabled")}
        >
            <div className="c-device__card__icon">
                <DeviceIcon item={item} />
            </div>
            <div className="c-device__card__body">
                {item.deviceName}
                {item.isDisabled && (
                    <div className="c-tag c-tag--rounded c-tag--sml c-tag--disabled u-mar--top--micro">Disabled</div>
                )}
            </div>
            {!item.isDisabled && (
                <div
                    className="c-device__card__delete"
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        actions.delete.onClick(item);
                    }}
                >
                    <i className="u-icon u-icon--lrg u-icon--delete"></i>
                </div>
            )}
        </div>
    );
}

DeviceGridCellTemplate.propTypes = {
    onCellClick: PropTypes.func,
    item: PropTypes.object.isRequired,
};

function DeviceIcon({ item }) {
    if (item.device && item.device.systemType && item.device.systemType.abrv === nakiRadioSystemTypes.mobile) {
        return <i className="u-icon u-icon--mobile" />;
    }
    return <i className="u-icon u-icon--radio--dark" />;
}

export default DeviceGridCellTemplate;
