import React from "react";
import ContentLoader from "react-content-loader";

const DowntimeNoteLoaderTemplate = ({ props, fieldWidth }) => {
    const screenwidth = window.innerWidth;
    let loaderWidth;

    if (fieldWidth && screenwidth > 988) {
        loaderWidth = fieldWidth;
    } else if (screenwidth > 1200) {
        loaderWidth = 380;
    } else if (screenwidth > 988) {
        loaderWidth = screenwidth - 240;
    } else {
        loaderWidth = screenwidth;
    }

    return (
        <ContentLoader
            speed={2}
            width={loaderWidth}
            height={70}
            viewBox={`0 0 ${loaderWidth} 70`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            <rect x="0" y="15" rx="45" ry="45" width="45" height="45" />
            <rect x="80" y="20" rx="6" ry="6" width="80%" height="36" />
        </ContentLoader>
    );
};

export default DowntimeNoteLoaderTemplate;
