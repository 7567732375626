import { action, computed, makeObservable, observable } from "mobx";
import { forEach } from "lodash";

class PodcastDeviceGridItemDecorator {
    @observable _userDevicePodcast = null;

    constructor(obj) {
        makeObservable(this);
        forEach(obj, (value, key) => {
            if (key === "userDevicePodcast") {
                this.setUserDevicePodcast(value);
            } else {
                this[key] = value;
            }
        });
    }

    @computed get userDevicePodcast() {
        return this._userDevicePodcast;
    }

    set userDevicePodcast(value) {
        this.setUserDevicePodcast(value);
    }

    @action.bound
    setUserDevicePodcast(item) {
        this._userDevicePodcast = item;
    }
}

export default PodcastDeviceGridItemDecorator;
