import { makeObservable, observable, computed, action } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { isNil, find } from "lodash";

class PodcastDeviceCellViewStore extends MrbBaseViewStore {
    podcastId = null;
    @observable isUpdating = false;

    @computed get deviceId() {
        return this.podcastDeviceViewStore.deviceId;
    }

    @computed get item() {
        return find(
            this.podcastDeviceViewStore.gridStore.data,
            (item) => item.id === this.podcastId
        );
    }

    @computed get isTurnedOn() {
        if (!isNil(this.item.userDevicePodcast)) {
            return this.item.userDevicePodcast.isTurnedOn;
        } else if (!isNil(this.item)) {
            return this.item.isTurnedOnByDefault;
        }
        return false;
    }

    constructor(rootStore, { podcastId, podcastDeviceViewStore }) {
        super(rootStore);
        makeObservable(this);
        this.podcastId = podcastId;
        this.podcastDeviceViewStore = podcastDeviceViewStore;
        this.routeStore = this.podcastDeviceViewStore.routeStore;
    }

    @action.bound
    async toggleIsTurnedOn(value) {
        this.setIsUpdating(true);
        await this.podcastDeviceViewStore.toggleIsTurnedOn(
            this.podcastId,
            value
        );
        this.setIsUpdating(false);
    }

    setIsUpdating(isUpdating) {
        this.isUpdating = isUpdating;
    }
}

export default PodcastDeviceCellViewStore;
