import React from "react";
import ContentLoader from "react-content-loader";

const SearchDeviceLoaderTemplate = (props) => {
    const screenwidth = window.innerWidth;

    return (
        <ContentLoader
            speed={2}
            width="100%"
            height={34}
            viewBox={`0 0 ${screenwidth} 34`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            <rect x="0" y="0" rx="6" ry="6" width="280" height="34" />
            <rect x="85%" y="0" rx="6" ry="6" width="15%" height="34" />
        </ContentLoader>
    );
};

export default SearchDeviceLoaderTemplate;
