import { MrbBaseListViewStore } from "mrb/core";
import { MrbTableViewStore } from "mrb/components";
import { applicationDefaults } from "common/constants";
import { mrbDefaultFormats } from "mrb/common/formatting";
import { MrbSelectStore } from "mrb/components/select";
import { nakiRadioRoles } from "common/constants";
import { filter, map, includes } from "lodash";
import { localizationService } from "common/localization";

class UserListViewStore extends MrbBaseListViewStore {
    constructor(rootStore) {
        super(rootStore, {
            queryConfig: {
                orderBy: "lastName",
                orderDirection: "asc",
            },
        });

        const roleNames = map(
            filter(Object.keys(nakiRadioRoles), (key) => key !== "accountOwnerRoleName" && key !== "anonymousRoleName"),
            (key) => nakiRadioRoles[key]
        );

        this.roleSelectStore = new MrbSelectStore(
            {
                isSearchable: false,
                virtualized: false,
                placeholder: localizationService.t("ADMINISTRATION.USER.LIST.FILTER.PLACEHOLDER"),
                actions: {
                    onChange: async (option) => {
                        if (option) {
                            this.queryUtility.filter.set("roleNames", option.value);
                        } else {
                            this.queryUtility.filter.set("roleNames", null);
                        }
                        await this.queryUtility.fetch();
                    },
                },
            },
            roleNames
        );
        const selectedRole = this.rootStore.routerStore.routerState.queryParams.roleNames;
        this.roleSelectStore.setSelectedItem(selectedRole);

        this.setGridStore(
            new MrbTableViewStore(this.rootStore, this.queryUtility, {
                onRowClick: (node) => {
                    this.rootStore.routerStore.goTo("master.administration.user.edit", { id: node.data.id });
                },
                columns: [
                    {
                        key: "firstName",
                        title: "ADMINISTRATION.USER.LIST.COLUMNS.FIRST_NAME",
                        sortable: true,
                    },
                    {
                        key: "lastName",
                        title: "ADMINISTRATION.USER.LIST.COLUMNS.LAST_NAME",
                        sortable: true,
                    },
                    {
                        key: "email",
                        title: "ADMINISTRATION.USER.LIST.COLUMNS.EMAIL",
                        sortable: true,
                    },
                    {
                        key: "isApproved",
                        title: "ADMINISTRATION.USER.LIST.COLUMNS.APPROVED",
                        sortable: true,
                        format: {
                            type: mrbDefaultFormats.bool,
                            trueLabel: "Approved",
                            falseLabel: "Disapproved",
                        },
                    },
                    {
                        key: "isLockedOut",
                        title: "ADMINISTRATION.USER.LIST.COLUMNS.LOCKED_OUT",
                        sortable: true,
                        format: {
                            type: mrbDefaultFormats.bool,
                            trueLabel: "Locked",
                            falseLabel: "Unlocked",
                        },
                    },
                    {
                        key: "creationDate",
                        title: "ADMINISTRATION.USER.LIST.COLUMNS.CREATED",
                        sortable: true,
                        format: {
                            type: mrbDefaultFormats.date,
                            format: applicationDefaults.formats.dateTime,
                        },
                    },
                    {
                        key: "lastActivityDate",
                        title: "ADMINISTRATION.USER.LIST.COLUMNS.LAST_ACTIVE",
                        sortable: true,
                        format: {
                            type: mrbDefaultFormats.date,
                            format: applicationDefaults.formats.dateTime,
                        },
                    },
                ],
            })
        );
    }

    async find(params) {
        if (params.search === "") {
            params.search = null;
        }
        const response = await this.rootStore.application.baasic.membershipModule.user.find(params);
        if (response.data != null && response.data.item != null) {
            const users = this.updateGuestEmail(response.data.item);
            response.data.item = users;
        }
        return response.data;
    }

    updateGuestEmail(users) {
        return map(users, (user) => {
            const userRoleNames = map(user.roles, (role) => role.name);
            if (includes(userRoleNames, nakiRadioRoles.guestRoleName) && user.guestEmail != null) {
                user.email = user.guestEmail;
            }
            return user;
        });
    }
}

export default UserListViewStore;
