import { MrbBaseRouteStore } from "mrb/core";
import {
    UserStationExpirationService,
    UserPodcastExpirationService,
} from "common/services";

class UserContentExpirationRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.userStationExpirationService = rootStore.createApplicationService(
            UserStationExpirationService
        );
        this.userPodcastExpirationService = rootStore.createApplicationService(
            UserPodcastExpirationService
        );
    }

    async findUserStationExpiration(params) {
        const response = await this.userStationExpirationService.find(params);
        return response.data;
    }

    async findUserPodcastExpiration(params) {
        const response = await this.userPodcastExpirationService.find(params);
        return response.data;
    }

    async deleteUserStationExpiration(id) {
        const response = await this.userStationExpirationService.delete({ id });
        return response.data;
    }

    async updateUserStationExpiration(resource) {
        const response = await this.userStationExpirationService.update(
            resource
        );
        return response.data;
    }

    async createUserStationExpiration(resource) {
        const response = await this.userStationExpirationService.create(
            resource
        );
        return response.data;
    }

    async deleteUserPodcastExpiration(id) {
        const response = await this.userPodcastExpirationService.delete({ id });
        return response.data;
    }

    async updateUserPodcastExpiration(resource) {
        const response = await this.userPodcastExpirationService.update(
            resource
        );
        return response.data;
    }

    async createUserPodcastExpiration(resource) {
        const response = await this.userPodcastExpirationService.create(
            resource
        );
        return response.data;
    }

    async getUser(userId) {
        const response =
            await this.rootStore.application.baasic.membershipModule.user.get(
                userId
            );
        return response.data;
    }
}

export default UserContentExpirationRouteStore;
