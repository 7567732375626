import { action, computed, makeObservable, runInAction } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { MrbGridViewStore } from "mrb/components/grid";
import { StationDeviceGridItemDecorator } from "administration/user/models";
import { find } from "lodash";

class StationDeviceViewStore extends MrbBaseListViewStore {
    @computed get deviceId() {
        return this.userDeviceSettingsViewStore.selectedDeviceId;
    }

    @computed get isInitalized() {
        return this.gridStore.dataInitialized;
    }

    @computed get isActiveStationTab() {
        return this.userDeviceSettingsViewStore.tabsStore.activeTabIndex === 0;
    }

    constructor(rootStore, userDeviceSettingsViewStore) {
        super(rootStore, {});
        makeObservable(this);
        this.userDeviceSettingsViewStore = userDeviceSettingsViewStore;
        this.routeStore = this.userDeviceSettingsViewStore.routeStore;

        this.setGridStore(
            new MrbGridViewStore(this.rootStore, this.queryUtility, {
                generateKey: (item) => item.id,
                mapper: (item) => new StationDeviceGridItemDecorator(item),
            })
        );

        this.reaction(
            () => this.deviceId,
            () => {
                if (this.isActiveStationTab) {
                    this.queryUtility.fetch();
                }
            }
        );

        this.reaction(
            () => this.queryUtility.filter.search,
            () => {
                if (this.isActiveStationTab) {
                    this.queryUtility.fetch();
                }
            },
            {
                delay: 300,
            }
        );
    }

    find(params) {
        if (this.deviceId) {
            params.deviceId = this.deviceId;
            return this.routeStore.findStationDevice(params);
        }
    }

    @action.bound
    async toggleIsTurnedOn(stationId, value) {
        const station = find(this.gridStore.data, (item) => item.id === stationId);
        if (station) {
            try {
                const response = await this.routeStore.updateStationDeviceTurnedOn(this.deviceId, stationId, value);
                runInAction(() => {
                    station.userDeviceStation = response;
                });
            } catch (err) {
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to update user device status.",
                    err
                );
            }
        }
    }
}

export default StationDeviceViewStore;
