import { MrbBaseEditViewStore } from "mrb/core";
import { UserPasswordChangeForm } from "administration/user/forms";

class UserPasswordChangeViewStore extends MrbBaseEditViewStore {
    constructor(rootStore, { modalParams }) {
        super(rootStore, {
            name: "password-change",
            FormClass: UserPasswordChangeForm,
            autoFocusField: "password",
            successCreateNotification: null,
            successUpdateNotification: null,
        });

        this.modalParams = modalParams;
        this.user = modalParams.data.user;
        this.routeStore = modalParams.data.routeStore;
    }

    create({ password, sendMailNotification }) {
        return this.routeStore.changeUserPassword(this.user.id, {
            newPassword: password,
            siteUrl: window.location.origin,
            sendMailNotification: sendMailNotification,
        });
    }

    onCreateSuccess() {
        this.modalParams.close();
    }

    onCreateError(error) {
        if (error.data && error.data.errorCode === 4000022103) {
            this.form.invalidate(
                "ADMINISTRATION.USER.PASSWORD_CHANGE.ERROR.USER_IS_DISAPPROVED"
            );
        } else {
            this.form.invalidate(
                "ADMINISTRATION.USER.PASSWORD_CHANGE.ERROR.GENERIC_MESSAGE"
            );
        }
    }
}

export default UserPasswordChangeViewStore;
