import { MrbBaseRouteStore } from "mrb/core";

class UserListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.userService = this.rootStore.application.baasic.membershipModule.user;
    }

    async findUsers(params) {
        const response = await this.userService.find(params);
        return response.data;
    }
}

export default UserListRouteStore;
