import React from "react";
import StationDeviceCellTemplate from "themes/administration/user/components/StationDeviceCellTemplate";
import { StationDeviceCellViewStore } from "administration/user/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new StationDeviceCellViewStore(rootStore, componentProps),
    "stationDeviceCellViewStore"
)
class StationDeviceCell extends React.Component {
    render() {
        return <StationDeviceCellTemplate {...this.props} />;
    }
}

export default StationDeviceCell;