import React from "react";
import ContentLoader from "react-content-loader";

const UserSettingsLoaderTemplate = (props) => {
    return (
        <div>
            <UserTabsTemplate {...props} />
            <div className="u-mar--top--sml">
                <PodcastStationListLoader rows={2} lineheight={38} setY={50} setHeight={100} {...props} />
            </div>
        </div>
    );
};

export default UserSettingsLoaderTemplate;

const UserTabsTemplate = (props) => {
    const screenwidth = window.innerWidth;
    const loaderWidth = screenwidth > 1024 ? 1024 : screenwidth;

    // prettier-ignore
    const { tabs = 2, backgroundColor = "#262729", foregroundColor = "#2b2b2b", dropdown} = props;
    const tabsItems = [];
    const tabsWidth = tabs * 140;
    let x = 20;

    for (let i = 0; i < tabs; i++) {
        tabsItems.push(
            <React.Fragment key={i}>
                <rect x={x} y="0" rx="3" ry="3" width="100" height="8" />
                {/* prettier-ignore */}
                <rect x="0" y="17" rx="3" ry="3" width={tabsWidth} height="2" />

                {dropdown && (
                    <React.Fragment>
                        {/* prettier-ignore */}
                        <rect x="86%" y="0" rx="3" ry="3" width="95" height="8" />
                        {/* prettier-ignore */}
                        <rect x="97%" y="2" rx="3" ry="3" width="20" height="4" />
                    </React.Fragment>
                )}

                {/* prettier-ignore */}
                <rect x="0" y="40" rx="6" ry="6" width="200" height="32" />
            </React.Fragment>
        );

        x += 120;
    }

    return (
        <ContentLoader
            speed={2}
            width={loaderWidth}
            height="80"
            viewBox={`0 0 ${loaderWidth} 80`}
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
        >
            {tabsItems}
        </ContentLoader>
    );
};

const PodcastStationListLoader = (props) => {
    const { backgroundColor = "#262729", foregroundColor = "#2b2b2b", dropdown, ...rest } = props;

    const screenwidth = window.innerWidth;
    const loaderWidth = screenwidth > 1024 ? 1024 : screenwidth;

    const items = [];
    let y = 0;

    for (let i = 0; i < 10; i++) {
        items.push(<rect key={i} x="0" y={y} rx="6" ry="6" width="100%" height="46" />);

        y += 60;
    }

    return (
        <ContentLoader
            speed={2}
            width={loaderWidth}
            height="100%"
            viewBox={`0 0 ${loaderWidth} ${y}`}
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
            {...rest}
        >
            {items}
        </ContentLoader>
    );
};
