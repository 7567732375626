import React from "react";
import { PropTypes } from "prop-types";
import {
    PodcastDeviceCell,
    UserListEmptyState,
} from "administration/user/components";
import { MrbGrid } from "mrb/components/grid";
import { MrbQuerySearchInput } from "mrb/components/input";
import { MrbLineContentLoader } from "mrb/components/loader";

function PodcastDeviceTemplate({ podcastDeviceViewStore, t }) {
    const { gridStore, queryUtility } = podcastDeviceViewStore;
    return (
        <div>
            <div className="u-mar--bottom--sml">
                <MrbQuerySearchInput
                    queryUtility={queryUtility}
                    showSearchButton={false}
                />
            </div>
            <MrbGrid
                emptyStateRender={UserListEmptyState}
                loaderRender={() => (
                    <MrbLineContentLoader
                        lineheight={65}
                        setY={85}
                        rows={10}
                        backgroundColor={"#33363B"}
                        foregroundColor={"#393C42"}
                    />
                )}
                classNameOverride="test"
                store={gridStore}
                t={t}
                cellRender={(props) => (
                    <PodcastDeviceCell
                        podcastDeviceViewStore={podcastDeviceViewStore}
                        podcastId={props.item.id}
                        {...props}
                    />
                )}
            />
        </div>
    );
}

PodcastDeviceTemplate.propTypes = {
    podcastDeviceViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default PodcastDeviceTemplate;
