import React from "react";

const UserListEmptyStateTemplate = () => {
    return (
        <div className="c-empty-state--no-results c-empty-state--no-results--absolute">
            <i className="u-icon--welcome u-icon--welcome--sml u-mar--bottom--sml"></i>
            <h3>No results found</h3>
            <p>Try adjusting your search to find what you are looking for.</p>
        </div>
    );
};

export default UserListEmptyStateTemplate;
