import React from "react";
import { PropTypes } from "prop-types";
import { MrbDatePicker } from "mrb/components/date-picker";
import { observer } from "mobx-react";
import moment from "moment";
import { isEmpty } from "lodash";

function UserPodcastExpirationCellTemplate({
    userPodcastExpirationCellViewStore,
}) {
    const { item, handleExpirationDateChange } =
        userPodcastExpirationCellViewStore;
    const today = moment().add(1, "day").startOf("day").toDate();
    return (
        <div className="c-card--list-item">
            <div className="u-display--flex u-display--flex--ai--center">
                {`${item.title}${
                    !isEmpty(item.podcastGroupTitle)
                        ? ` ${item.podcastGroupTitle}`
                        : ""
                }`}
                {item.isPremium && (
                    <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny"></i>
                )}
            </div>
            <div>
                <span className="u-type--sml u-mar--right--sml">Exp. Date</span>
                <MrbDatePicker
                    value={item.expirationDate}
                    onChange={handleExpirationDateChange}
                    minDate={today}
                    todayButtonLabel={null}
                />
            </div>
        </div>
    );
}

UserPodcastExpirationCellTemplate.propTypes = {
    userPodcastExpirationCellViewStore: PropTypes.object.isRequired,
};

export default observer(UserPodcastExpirationCellTemplate);
