import React from "react";
import { PropTypes } from "prop-types";
import { MrbPage, MrbEditContent, MrbPageHeader } from "mrb/components/layout";
import { MrbButton } from "mrb/components/button";
import { observer } from "mobx-react";

function DeviceMenuEditPageLayoutTemplate({
    store,
    t,
    children,
    ...pageProps
}) {
    return (
        <MrbPage t={t} {...pageProps}>
            <MrbPageHeader>
                <div className="c-page__header--top">
                    <div>{t(store.title)}</div>

                    <div className="c-header__actions c-header__actions--end">
                        <Actions store={store} t={t} />
                    </div>
                </div>
            </MrbPageHeader>
            <MrbEditContent>{children}</MrbEditContent>
        </MrbPage>
    );
}

DeviceMenuEditPageLayoutTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    children: PropTypes.any,
};

const Actions = observer(function Actions({ store, t }) {
    const { layoutActions, submitting } = store;
    return (
        <React.Fragment>
            <MrbButton
                t={t}
                className="c-btn c-btn--base c-btn--primary"
                onClick={layoutActions.save.onClick}
                aria-label={layoutActions.save.label}
                aria-busy={submitting}
                label={layoutActions.save.label}
                disabled={submitting}
            />
        </React.Fragment>
    );
});

export default observer(DeviceMenuEditPageLayoutTemplate);
