import React from "react";
import { PropTypes } from "prop-types";
import { MrbRouterLink } from "mrb/core/infrastructure/router";

const UserDeviceSettingsEmptyStateTemplate = ({ userId }) => {
    return (
        <div className="c-empty-state c-empty-state--no-results c-empty-state--no-results--absolute">
            <i className="u-icon u-icon--xxlrg u-icon--empty-state" />
            <h3 className="c-empty-state__title">The user doesn't have any connected device</h3>
            <p className="c-empty-state__desc">Station and podcasts with default settings will be used.</p>
            <MrbRouterLink
                routeName="master.administration.user.edit"
                params={{ id: userId }}
                className="c-btn c-btn--base c-btn--ghost"
            >
                Go back
            </MrbRouterLink>
        </div>
    );
};

UserDeviceSettingsEmptyStateTemplate.propTypes = {
    userId: PropTypes.string.isRequired,
};

export default UserDeviceSettingsEmptyStateTemplate;
