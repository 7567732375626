import { action, computed, makeObservable, observable } from "mobx";
import { forEach } from "lodash";

class StationDeviceGridItemDecorator {
    @observable _userDeviceStation = null;

    constructor(obj) {
        makeObservable(this);
        forEach(obj, (value, key) => {
            if (key === "userDeviceStation") {
                this.setUserDeviceStation(value);
            } else {
                this[key] = value;
            }
        });
    }

    @computed get userDeviceStation() {
        return this._userDeviceStation;
    }

    set userDeviceStation(value) {
        this.setUserDeviceStation(value);
    }

    @action.bound
    setUserDeviceStation(item) {
        this._userDeviceStation = item;
    }
}

export default StationDeviceGridItemDecorator;
