import { makeObservable, observable, computed, action, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { find } from "lodash";

class UserDeviceCellViewStore extends MrbBaseViewStore {
    userDeviceId = null;
    @observable isUpdating = false;

    @computed get item() {
        return find(this.userDeviceListViewStore.gridStore.data, (item) => item.id === this.userDeviceId);
    }

    constructor(rootStore, { userDeviceId, userDeviceListViewStore }) {
        super(rootStore);
        makeObservable(this);
        this.userDeviceId = userDeviceId;
        this.userDeviceListViewStore = userDeviceListViewStore;
        this.routeStore = this.userDeviceListViewStore.routeStore;
    }

    @action.bound
    async toggleIsDisabled(userDevice, value) {
        this.setIsUpdating(true);
        await this.updateUserDeviceDisabledStatus(userDevice, value);
        this.setIsUpdating(false);
    }

    async updateUserDeviceDisabledStatus(userDevice, value) {
        const oldValue = value;
        userDevice.isDisabled = value;
        try {
            await this.routeStore.updateUserDeviceDisabledStatus(userDevice.id, userDevice.isDisabled);
        } catch (err) {
            runInAction(() => {
                userDevice.isDisabled = oldValue;
                this.rootStore.notificationStore.error(
                    "An unexpected error occurred while trying to update device status. Please contact the support team.",
                    err
                );
            });
        }
    }

    setIsUpdating(isUpdating) {
        this.isUpdating = isUpdating;
    }
}

export default UserDeviceCellViewStore;
