import { LocalizedForm } from "common/localization";

export default class UserEditForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "email",
                    label: "ADMINISTRATION.USER.EDIT.FIELDS.EMAIL_LABEL",
                    placeholder: "ADMINISTRATION.USER.EDIT.FIELDS.EMAIL_PLACEHOLDER",
                    rules: "required|email|string",
                },
                {
                    name: "firstName",
                    label: "ADMINISTRATION.USER.EDIT.FIELDS.FIRST_NAME_LABEL",
                    placeholder: "ADMINISTRATION.USER.EDIT.FIELDS.FIRST_NAME_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "lastName",
                    label: "ADMINISTRATION.USER.EDIT.FIELDS.LAST_NAME_LABEL",
                    placeholder: "ADMINISTRATION.USER.EDIT.FIELDS.LAST_NAME_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "isAdministrator",
                    label: "ADMINISTRATION.USER.EDIT.FIELDS.IS_ADMINISTRATOR_LABEL",
                    type: "checkbox",
                },
            ],
        };
    }
}
