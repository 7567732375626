import { MrbBaseRouteStore } from "mrb/core";
import { DeviceService } from "common/services";

class UserDeviceListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(DeviceService);
    }

    async findUserDevice(filter) {
        const response = await this.service.findUserDevice(filter);
        return response.data;
    }

    async updateUserDeviceDisabledStatus(id, isDisabled) {
        const response = await this.service.updateUserDeviceDisabledStatus(id, isDisabled);
        return response.data;
    }

    async getUser(userId) {
        const response = await this.rootStore.application.baasic.membershipModule.user.get(userId);
        return response.data;
    }
}

export default UserDeviceListRouteStore;
