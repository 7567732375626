import React from "react";
import { PropTypes } from "prop-types";
import { MrbButton } from "mrb/components/button";
import { DeviceMenuTypeIcon, LatestItemsLoader } from "administration/device-menu/components";
import { observer } from "mobx-react";
import { map } from "lodash";
import { MrbContentLoader } from "baasic-react-bootstrap/src";
function DeviceMenuLatestItemsTemplate({ store, t }) {
    const {
        deviceMenuLatestItemsViewStore: { latestItems, onClickMoveToTop },
        submitting,
        loaderStore,
    } = store;

    return (
        <React.Fragment>
            <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <LatestItemsLoader />}>
                {() => (
                    <div className="u-animation--fadeIn">
                        <div className="c-device-menu__card__header">
                            <p className="u-type--base">Latest items</p>
                        </div>

                        <div className="c-device-menu__list">
                            {map(latestItems, (item, index) => (
                                <LatestItem
                                    key={index}
                                    item={item}
                                    onClickMoveToTop={onClickMoveToTop}
                                    isDisabled={submitting}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </MrbContentLoader>
        </React.Fragment>
    );
}

DeviceMenuLatestItemsTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const LatestItem = observer(function LatestItem({ item, onClickMoveToTop, isDisabled }) {
    return (
        <div className="c-device-menu__item c-device-menu__item--secondary">
            <div className="c-device-menu__item__info">
                <DeviceMenuTypeIcon type={item.deviceMenuType} />
                <span className="u-type--wgt--bold u-mar--right--tny">{item.index + 1}.</span>
                <div className="c-device-menu__item__title" title={item.title}>
                    {item.title}
                </div>
            </div>
            {item.isPremium && (
                <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny u-mar--right--tny"></i>
            )}
            {item.index > 0 && (
                <MrbButton
                    label="Bring to the top"
                    classNameOverride="c-btn c-btn--sml c-btn--primary"
                    onClick={() => onClickMoveToTop(item)}
                    disabled={isDisabled}
                />
            )}
        </div>
    );
});

export default observer(DeviceMenuLatestItemsTemplate);
