import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { MrbToggle } from "baasic-react-bootstrap/src";

function StationDeviceCellTemplate({ item, stationDeviceCellViewStore }) {
    const { toggleIsTurnedOn, isTurnedOn, isUpdating } =
        stationDeviceCellViewStore;
    return (
        <div className="c-card--list-item">
            <div className="u-display--flex u-display--flex--ai--center">
                {item.title}
                {item.isPremium && (
                    <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny"></i>
                )}
            </div>
            <div>
                <MrbToggle
                    id={item.id}
                    value={isTurnedOn}
                    disabled={isUpdating}
                    onChange={(event) => toggleIsTurnedOn(event.target.checked)}
                />
            </div>
        </div>
    );
}

StationDeviceCellTemplate.propTypes = {
    stationDeviceCellViewStore: PropTypes.object.isRequired,
};

export default observer(StationDeviceCellTemplate);
