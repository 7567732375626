import React from "react";
import { PropTypes } from "prop-types";
import { mergeCss } from "mrb/common/utils";
import { DeviceMenuTypeIcon, SearchResultsEmptyLoader } from "administration/device-menu/components";
import { observer } from "mobx-react";
import { map, isNil } from "lodash";
import { MrbContentLoader } from "baasic-react-bootstrap/src";
function DeviceMenuSearchResultTemplate({ loaderStore, store, t }) {
    const { searchResult, setSearchContainerRef } = store;

    const moreResults = searchResult
        ? searchResult.totalResults - searchResult.items.length
        : 0;

    return (
        <React.Fragment>
            <MrbContentLoader
                loaderStore={loaderStore}
                loaderRender={() => (
                    <SearchResultsEmptyLoader />
                )}
            >
                {() => (
                    <div className="u-animation--fadeIn">
                        <div className="c-device-menu__card__header">
                            <div>
                                <p className="u-type--base">Search results</p>
                                {moreResults > 0 && (
                                    <React.Fragment>
                                        <p className="u-type--sml u-type--color--opaque">
                                            {moreResults} more results found
                                        </p>
                                    </React.Fragment>
                                )}
                            </div>
                            {!isNil(searchResult) &&
                                searchResult.items.length > 0 && (
                                    <div className="c-device-menu__note">
                                        Use keyboard arrows to move Up
                                        <i className="u-icon--med u-icon--arrow-fill-up u-mar--left--micro u-mar--right--micro"></i>
                                        or Down
                                        <i className="u-icon--med u-icon--arrow-fill-down u-mar--left--micro"></i>
                                    </div>
                                )}
                        </div>

                        {isNil(searchResult) ? (
                            <div className="c-empty-state--no-results">
                                <i className="u-icon--welcome u-icon--welcome--sml u-mar--bottom--sml"></i>
                                <h3>You haven't searched for anything yet</h3>
                                <p>
                                    You can use Search bar to find the items in
                                    the device menu.
                                </p>
                            </div>
                        ) : (
                            <React.Fragment>
                                {searchResult.items.length === 0 ? (
                                    <div className="c-empty-state--no-results">
                                        <i className="u-icon--welcome u-icon--welcome--sml u-mar--bottom--sml"></i>
                                        <h3>No results found</h3>
                                        <p>
                                            Try adjusting your search to find
                                            what you are looking for.
                                        </p>
                                    </div>
                                ) : (
                                    <div
                                        className="c-device-menu__list c-device-menu__list--results"
                                        ref={setSearchContainerRef}
                                    >
                                        {map(
                                            searchResult.items,
                                            (item, index) => (
                                                <SearchResultItem
                                                    key={item.id}
                                                    searchResult={searchResult}
                                                    item={item}
                                                    index={index}
                                                />
                                            )
                                        )}
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                )}
            </MrbContentLoader>
        </React.Fragment>
    );
}

DeviceMenuSearchResultTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const SearchResultItem = observer(function SearchResultItem({
    searchResult,
    item,
    index,
}) {
    const isSelected = searchResult.position === index;
    return (
        <div
            onClick={() => searchResult.setPosition(index)}
            className={mergeCss(
                "c-device-menu__item",
                isSelected ? "c-device-menu__item--selected" : null
            )}
        >
            <div>
                <DeviceMenuTypeIcon type={item.deviceMenuType} />
                <span className="u-type--wgt--bold u-mar--right--tny">
                    {item.index + 1}.
                </span>
                {item.title}
            </div>
        </div>
    );
});

export default observer(DeviceMenuSearchResultTemplate);
