import { action, computed, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { MrbTabViewStore } from "mrb/components/tabs";
import { MrbSimpleSelectStore } from "baasic-react-bootstrap/src";
import {
    StationDeviceViewStore,
    PodcastDeviceViewStore,
} from "administration/user/stores";

class UserDeviceSettingsViewStore extends MrbBaseViewStore {
    @observable.ref user = null;

    @computed get selectedDeviceId() {
        return this.deviceSelectStore.value;
    }

    @computed get anyDevice() {
        return (
            this.deviceSelectStore.options &&
            this.deviceSelectStore.options.length > 0
        );
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.tabsStore = new MrbTabViewStore(this.rootStore, {
            actions: {
                onTabChange: (tabIndex) => {
                    if (
                        tabIndex === 0 &&
                        !this.stationDeviceViewStore.isInitalized
                    ) {
                        this.stationDeviceViewStore.initialize();
                    } else if (
                        tabIndex === 1 &&
                        !this.podcastDeviceViewStore.isInitalized
                    ) {
                        this.podcastDeviceViewStore.initialize();
                    }
                },
            },
        });
        this.rootStore = rootStore;
        this.routeStore = routeStore;
        this.userId = rootStore.routerStore.routerState.params.id;

        this.deviceSelectStore = new MrbSimpleSelectStore({
            isSearchable: false,
            textKey: "deviceName",
            preselectFirstItemAsDefault: true,
            initFetch: false,
            actions: {
                find: () => {
                    return this.routeStore.findUserDevices({
                        userIds: this.userId,
                    });
                },
            },
        });

        this.stationDeviceViewStore = new StationDeviceViewStore(
            this.rootStore,
            this
        );
        this.podcastDeviceViewStore = new PodcastDeviceViewStore(
            this.rootStore,
            this
        );
    }

    async onInit() {
        await Promise.all([
            this.loadUser(this.userId),
            this.deviceSelectStore.initializeOriginalItems(),
        ]);
    }

    async loadUser(userId) {
        const user = await this.routeStore.getUser(userId);
        this.setUser(user);
    }

    @action.bound
    setUser(user) {
        this.user = user;
    }
}

export default UserDeviceSettingsViewStore;
