import React from "react";
import { renderTabs } from "mrb/components/tabs/utils";
import { MrbSimpleSelect } from "baasic-react-bootstrap/src";
import { PropTypes } from "prop-types";

function UserDeviceSettingsTabHeadersTemplate({
    store,
    deviceSelectStore,
    children,
    t,
}) {
    return (
        <nav className="c-tabs__navigation">
            <ul className="c-tabs">{renderTabs(store, children, t)}</ul>
            <MrbSimpleSelect
                store={deviceSelectStore}
                valueRender={(props) => <DeviceSelectValue {...props} />}
            />
        </nav>
    );
}

UserDeviceSettingsTabHeadersTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    deviceSelectStore: PropTypes.object.isRequired,
    children: PropTypes.node,
    t: PropTypes.func,
};

function DeviceSelectValue({ selectedOption }) {
    return (
        <div className="u-display--flex u-display--flex--ai--center">
            <i className="u-icon u-icon--switch-device u-mar--right--tny"></i>
            {selectedOption.label}
        </div>
    );
}

DeviceSelectValue.propTypes = {
    selectedOption: PropTypes.object,
};

export default UserDeviceSettingsTabHeadersTemplate;
