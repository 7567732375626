import { action, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { MrbTabViewStore } from "mrb/components/tabs";
import {
    UserStationExpirationViewStore,
    UserPodcastExpirationViewStore,
} from "administration/user/stores";

class UserContentExpirationViewStore extends MrbBaseViewStore {
    userId = null;

    @observable.ref user = null;

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;
        this.userId = rootStore.routerStore.routerState.params.id;

        this.tabsStore = new MrbTabViewStore(this.rootStore, {
            actions: {
                onTabChange: (tabIndex) => {
                    if (
                        tabIndex === 0 &&
                        !this.userStationExpirationViewStore.isInitalized
                    ) {
                        this.userStationExpirationViewStore.initialize();
                    } else if (
                        tabIndex === 1 &&
                        !this.userPodcastExpirationViewStore.isInitalized
                    ) {
                        this.userPodcastExpirationViewStore.initialize();
                    }
                },
            },
        });
        this.userStationExpirationViewStore =
            new UserStationExpirationViewStore(this.rootStore, this);
        this.userPodcastExpirationViewStore =
            new UserPodcastExpirationViewStore(this.rootStore, this);
    }

    onInit() {
        this.loadUser(this.userId);
        this.userStationExpirationViewStore.initialize();
    }

    async loadUser(userId) {
        const user = await this.routeStore.getUser(userId);
        this.setUser(user);
    }

    @action.bound
    setUser(user) {
        this.user = user;
    }
}

export default UserContentExpirationViewStore;
