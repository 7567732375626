import { action, makeObservable, observable, computed } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { isNil, find } from "lodash";

class StationDeviceCellViewStore extends MrbBaseViewStore {
    stationId = null;

    @observable isUpdating = false;

    @computed get deviceId() {
        return this.stationDeviceViewStore.deviceId;
    }

    @computed get item() {
        return find(
            this.stationDeviceViewStore.gridStore.data,
            (item) => item.id === this.stationId
        );
    }

    @computed get isTurnedOn() {
        if (!isNil(this.item.userDeviceStation)) {
            return this.item.userDeviceStation.isTurnedOn;
        } else if (!isNil(this.item)) {
            return this.item.isTurnedOnByDefault;
        }
        return false;
    }

    constructor(rootStore, { stationId, stationDeviceViewStore }) {
        super(rootStore);
        makeObservable(this);
        this.stationId = stationId;
        this.stationDeviceViewStore = stationDeviceViewStore;
        this.routeStore = this.stationDeviceViewStore.routeStore;
    }

    @action.bound
    async toggleIsTurnedOn(value) {
        this.setIsUpdating(true);
        await this.stationDeviceViewStore.toggleIsTurnedOn(
            this.stationId,
            value
        );
        this.setIsUpdating(false);
    }

    setIsUpdating(isUpdating) {
        this.isUpdating = isUpdating;
    }
}

export default StationDeviceCellViewStore;
