import { observable, action, makeObservable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { isNil } from "lodash";

class UserStationExpirationCellViewStore extends MrbBaseViewStore {
    @observable item = null;
    @observable isDisabled = false;

    get userId() {
        return this.userStationExpirationViewStore.userId;
    }

    constructor(rootStore, { item, userStationExpirationViewStore }) {
        super(rootStore);
        makeObservable(this);
        this.setItem(item);
        this.userStationExpirationViewStore = userStationExpirationViewStore;
        this.routeStore = this.userStationExpirationViewStore.routeStore;
    }

    @action.bound
    setItem(item) {
        this.item = item;
    }

    @action.bound
    setIsDisabled(isDisabled) {
        this.isDisabled = isDisabled;
    }

    handleExpirationDateChange = (value) => {
        if (isNil(value) && !isNil(this.item.userStationExpirationId)) {
            return this.deleteExpirationDate();
        } else {
            if (!isNil(this.item.userStationExpirationId)) {
                return this.updateExpirationDate(value);
            } else {
                return this.createExpirationDate(value);
            }
        }
    };

    @action.bound
    async createExpirationDate(value) {
        this.setIsDisabled(true);
        const response =
            await this.userStationExpirationViewStore.createExpirationDate(
                this.item.stationId,
                value
            );
        runInAction(() => {
            this.item = response;
            this.setIsDisabled(false);
        });
    }

    @action.bound
    async updateExpirationDate(value) {
        this.setIsDisabled(true);
        const response =
            await this.userStationExpirationViewStore.updateExpirationDate(
                this.item.stationId,
                value
            );
        runInAction(() => {
            this.item = response;
            this.setIsDisabled(false);
        });
    }

    @action.bound
    async deleteExpirationDate() {
        this.setIsDisabled(true);
        const response =
            await this.userStationExpirationViewStore.deleteExpirationDate(
                this.item.stationId
            );
        runInAction(() => {
            this.item = response;
            this.setIsDisabled(false);
        });
    }
}

export default UserStationExpirationCellViewStore;
