import { action, observable, makeObservable } from "mobx";
import { MrbGridViewStore } from "mrb/components/grid";
import { MrbBaseListViewStore } from "mrb/core";

class UserDeviceListViewStore extends MrbBaseListViewStore {
    userId = null;
    @observable.ref user = null;

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            queryConfig: {
                orderBy: "deviceName",
                orderDirection: "asc",
                embed: "device,device.systemType",
            },
        });
        makeObservable(this);
        this.routeStore = routeStore;
        this.userId = id;
        this.setGridStore(
            new MrbGridViewStore(this.rootStore, this.queryUtility, {
                generateKey: (item) => item.id,
            })
        );
    }

    async onInit() {
        await Promise.all([this.loadUser(this.userId), this.queryUtility.initialize()]);
    }

    async find(params) {
        try {
            params.userIds = this.userId;
            return await this.routeStore.findUserDevice(params);
        } catch (error) {
            this.onFetchError(error);
        }
    }

    onFetchError(error) {
        this.rootStore.notificationStore.error(
            "An unexpected error occurred while trying to load data. Please contact the support team."
        );
    }

    async loadUser(userId) {
        const user = await this.routeStore.getUser(userId);
        this.setUser(user);
    }

    @action.bound
    setUser(user) {
        this.user = user;
    }
}

export default UserDeviceListViewStore;
