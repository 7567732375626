import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { nakiRadioSystemTypes } from "common/constants";
import { dateFormatter } from "mrb/common/formatting";
import { applicationDefaults } from "common/constants";
import { MrbToggle } from "mrb/components/checkbox";

function UserDeviceCell({ item, userDeviceCellViewStore, t }) {
    const { toggleIsDisabled, isUpdating } = userDeviceCellViewStore;

    return (
        <div className="c-card--list-item" key={item.id}>
            <div className="u-display--flex u-display--flex--ai--center u-gap--sml">
                <DeviceIcon item={item} />
                <div>
                    <div>{item.deviceName}</div>
                    <div className="u-type--sml u-type--color--opaque">
                        {t("ADMINISTRATION.USER.USER_DEVICES.ITEM.CONNECTED")}:{" "}
                        {dateFormatter.format(item.dateCreated, {
                            format: applicationDefaults.formats.date,
                        })}
                    </div>
                </div>
            </div>
            {item.device.systemType.abrv === nakiRadioSystemTypes.venice ? (
                <MrbToggle
                    id={`${item.id}`}
                    value={!item.isDisabled}
                    label="Active"
                    labelClassNameExtend=" u-mar--top--tny"
                    disabled={isUpdating}
                    onChange={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        toggleIsDisabled(item, !item.isDisabled);
                    }}
                />
            ) : (
                <p className="u-type--sml u-type--color--warning--light">Mobile device</p>
            )}
        </div>
    );
}

function DeviceIcon({ item }) {
    if (item.device && item.device.systemType && item.device.systemType.abrv === nakiRadioSystemTypes.mobile) {
        return <i className="u-icon u-icon--lrg u-icon--mobile--light" />;
    }
    return <i className="u-icon u-icon--lrg u-icon--radio--light" />;
}

UserDeviceCell.propTypes = {
    userDeviceCellViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(UserDeviceCell);
